import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import styled from 'styled-components'
import CarpoolPortraits from '../components/carpool_portraits.svg'

const HeaderStyles = styled.header`
  position: sticky;
  top: 0;
  padding: 20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 1;
  
  a {
    display: block;
  }
  
  img {
    margin-bottom: 0;
  }
`

const Header = ({ siteTitle }) => (
  <HeaderStyles>
    <div>
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`
        }}
      >
        <img src={CarpoolPortraits} alt={siteTitle} />
      </Link>
    </div>
  </HeaderStyles>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
