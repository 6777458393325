/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'

import SCCLogo from '../components/SCC-Logo-v2.svg'

import Header from "./header"
import "./layout.css"

const LayoutGrid = styled.div`
  display: grid;
  grid-template-rows: 187px auto 80px;
  // height: 100vh;
`

const Footer = styled.footer`
  background-color: rgba(255, 255, 255, 0.95);
  position: sticky;
  bottom: 0;
  padding: 20px;
  
  img {
    margin-bottom: 0;
  }
  
  a {
    display: block;
  }
`

const Dots = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23${props => props.color ? props.color : 'F035C7'}' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
  width: 500px;
  height: ${props => props.height ? props.height : 180}px;
  position: fixed;
  top: ${props => props.bottom ? 'unset' : '-40px'};
  left: ${props => props.bottom ? 'unset' : '-420px'};
  bottom: ${props => props.bottom ? '0' : 'unset'};
  right: ${props => props.bottom ? '-400px' : 'unset'};
  z-index: 10;
  // z-index: -1;
  transition: all 0.25s ease-in-out;
  
  @media (min-width: 1024px) {
    top: ${props => props.bottom ? 'unset' : '0'};
    left: ${props => props.bottom ? 'unset' : '40px'};
    bottom: ${props => props.bottom ? '0' : 'unset'};
    right: ${props => props.bottom ? '-100px' : 'unset'};
    transition: all 0.25s ease-in-out;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Dots />
    <LayoutGrid>
      <Header siteTitle={data.site.siteMetadata.title} />


      <main>
        {children}
      </main>

      <Footer>
        <a href="//mysecondchancechurch.com">
          <img src={SCCLogo} alt="Second Chance Church" style={{ maxHeight: 40, width: 'auto' }} />
        </a>
      </Footer>
    </LayoutGrid>
      <Dots color={'3AACA2'} height={60} bottom />
      </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
